import React from 'react'
import { Text, Button } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'

interface Props {
  onClick: () => void
}

export const ResendCode = ({ onClick }: Props) => {
  const { t } = useTranslation('auth')
  const [delay, setDelay] = React.useState(60)

  const isDelayed = delay !== 0

  const handleClick = React.useCallback(() => {
    if (isDelayed) return

    onClick()
    setDelay(60)
  }, [isDelayed, onClick])

  React.useEffect(() => {
    if (delay === 0) return
    const interval = setInterval(() => {
      setDelay((prev) => (prev === 0 ? 0 : prev - 1))
    }, 1000)
    return () => clearInterval(interval)
  }, [delay])

  return (
    <Text opacity={0.6} textAlign="center" fontSize="sm">
      {t('CodeScreen.didntReceiveCode')}{' '}
      <Button
        as="a"
        variant="link"
        size="sm"
        fontWeight="bold"
        onClick={handleClick}
        isDisabled={isDelayed}
      >
        {t('CodeScreen.resendCode')}
        {isDelayed && ` (${delay}s)`}
      </Button>
    </Text>
  )
}
