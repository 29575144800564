import { HStack, Icon, Text } from '@chakra-ui/react'
import { FiCheck, FiX } from 'react-icons/fi'

interface Props {
    meets: boolean
    label: string
}

export const PasswordRequirement = ({ meets, label }: Props) => (
    <HStack
        color={meets ? 'teal.500' : 'red.500'}
        style={{ display: 'flex', alignItems: 'center' }}
        fontSize="sm"
    >
        <Icon as={meets ? FiCheck : FiX} />
        <Text>{label}</Text>
    </HStack>
)
