import React from 'react'
import { Button, Text } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import { getRoute } from 'utils/helpers'
import Link from 'next/link'

export const CreateOrLoginLink: React.FC<{ isLogin?: boolean }> = ({ isLogin }) => {
  const { t } = useTranslation('auth')
  return (
    <Text color="primary.600" textAlign="center" py="6" fontSize="sm">
      {isLogin ? (
        <>
          {t('dontHaveAnAccount')}{' '}
          <Button as={Link} variant="link" size="sm" fontWeight="bold" href={getRoute('signup')}>
            {t('signUp')}
          </Button>
        </>
      ) : (
        <>
          {t('doYouHaveAnAccount')}{' '}
          <Button as={Link} variant="link" size="sm" fontWeight="bold" href={getRoute('login')}>
            {t('signIn')}
          </Button>
        </>
      )}
    </Text>
  )
}
