import React from 'react'
import {
  chakra,
  HStack,
  Stack,
  Text,
  Checkbox,
  Button,
  Icon,
  FormControl,
  FormErrorMessage,
  useDisclosure,
  Container,
  useToast,
} from '@chakra-ui/react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { signIn } from 'next-auth/react'
import useTranslation from 'next-translate/useTranslation'
import { useRegisterMutation } from 'src/generated/graphql-frontend'
import { useRouter } from 'next/router'
import { FiMail } from 'react-icons/fi'
import { MdKey } from 'react-icons/md'
import { Layout } from 'components/Signup/components'
import { emailValidationRegisterOptions, InputWrapper } from 'components/InputWrapper'
import { StrikeThrough } from 'components/StrikeThrough'
import { CreateOrLoginLink } from 'components/CreateOrLoginLink'
import { getRoute } from 'utils/helpers'
import { PasswordStrength, getPasswordStrength, minPasswordLength } from '../PasswordStrength'
import { ResendCode } from './ResendCode'

const SocialButton = chakra('button', {
  baseStyle: {
    bg: 'gray.200',
    flex: 'none',
    width: '60px',
    height: '60px',
    display: 'block',
    cursor: 'pointer',
    borderRadius: '50%',
    transition: 'background 0.2s ease-out',
    userSelect: 'none',
    _hover: {
      bg: 'teal.100',
    },
    _active: {
      bg: 'gray.300',
    },
  },
})

export interface AuthForm {
  email: string
  password?: string
  agreement?: boolean
  code?: string
}

export interface AuthScreenProps {
  email?: string
  isLogin?: boolean
}

// TODO: Clean up spaghetti code because we had to launch already
export const AuthScreen: React.FC<AuthScreenProps> = ({ email, isLogin }) => {
  const { t } = useTranslation('auth')
  const { query, pathname, replace, push } = useRouter()
  const [isSigningIn, setIsSigningIn] = React.useState(false)
  const form = useForm<AuthForm>({
    defaultValues: {
      email: email || '',
    },
  })

  const {
    formState: { errors, isSubmitting },
    setError,
    register,
    handleSubmit,
    getValues,
    control,
  } = form

  const toast = useToast()

  const [registerMutation] = useRegisterMutation()

  const { isOpen: isPasswordFieldOpen, onToggle: onTogglePasswordFieldOpen } = useDisclosure({
    defaultIsOpen: !isLogin,
  })

  const { isOpen: isCodeFieldOpen, onOpen: onOpenCodeField } = useDisclosure()

  const onSubmit = async ({ email, password, code }: AuthForm) => {
    if (isLogin) {
      if (isPasswordFieldOpen && password && password.length > 0) {
        const res = await signIn('credentials', {
          email: email.toLowerCase(),
          password,
          callbackUrl: `${process.env.NEXT_PUBLIC_APP_URL}${getRoute('role')}`,
          redirect: false,
        })

        if (res?.error) {
          console.error('Email sign in login error', res.error)
          toast({
            description: t('AuthScreen.incorrectEmailOrPassword'),
            status: 'error',
            duration: 9000,
            isClosable: true,
          })
        } else if (res?.ok) {
          window.location.href = res.url || `${process.env.NEXT_PUBLIC_APP_URL}${getRoute('role')}`
        }
      } else if (!isCodeFieldOpen) {
        setIsSigningIn(true)
        const res = await signIn('email', {
          email: email.toLowerCase(),
          callbackUrl: `${process.env.NEXT_PUBLIC_APP_URL}${getRoute('role')}`,
          redirect: false,
        })

        if (res?.error) {
          console.error('Email sign in login error', res.error)
          setIsSigningIn(false)
          toast({
            title: t('error'),
            description: res.error,
            status: 'error',
            duration: 9000,
            isClosable: true,
          })
        } else if (res?.ok) {
          setIsSigningIn(false)
          onOpenCodeField()
        }
      } else if (code) {
        setIsSigningIn(true)
        const res = await fetch(
          `/api/auth/callback/email?callbackUrl=${encodeURIComponent(
            `${process.env.NEXT_PUBLIC_APP_URL}${getRoute('role')}`
          )}&email=${encodeURIComponent(email.toLowerCase())}&token=${code.trim()}`
        )
        if (res.redirected) {
          if (res.url.includes('/error')) {
            setError('code', { message: t('CodeScreen.incorrectCode') })
            setIsSigningIn(false)
          } else {
            window.location.href = res.url
          }
        }
        return
      }
    } else if (password) {
      let successfulRegistration = false
      try {
        await registerMutation({
          variables: {
            email: email.toLowerCase(),
            password,
          },
        })
        successfulRegistration = true
      } catch (error) {
        console.log(error)
      }

      if (successfulRegistration) {
        setIsSigningIn(true)
        const res = await signIn('credentials', {
          email: email.toLowerCase(),
          password,
          callbackUrl: `${process.env.NEXT_PUBLIC_APP_URL}${getRoute('role')}`,
        })

        if (res?.error) {
          console.error('Sign up login error', res.error)
          setIsSigningIn(false)
          toast({
            title: t('error'),
            description: res.error,
            status: 'error',
            duration: 9000,
            isClosable: true,
          })
        }
      }
    }
  }

  const onTogglePasswordOrSubmitIfEmailIsPresent = async () => {
    if (isPasswordFieldOpen) {
      const password = getValues('password')
      if (getValues('email').length > 1 && (!password || password.length === 0)) {
        await handleSubmit(onSubmit)()
      } else {
        onTogglePasswordFieldOpen()
        return
      }
    }
    onTogglePasswordFieldOpen()
  }

  const handleResendCode = async () => {
    const email = getValues('email')
    if (email.length > 0) {
      const res = await signIn('email', {
        email: email.toLowerCase(),
        callbackUrl: `${process.env.NEXT_PUBLIC_APP_URL}${getRoute('role')}`,
        redirect: false,
      })

      if (res?.error) {
        console.error('Email sign in login error', res.error)
        toast({
          title: t('error'),
          description: res.error,
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      } else if (res?.ok) {
        onOpenCodeField()
      }
    }
  }

  const handleForgotPassword = () => {
    console.log(getValues('email'))
    void push({
      pathname: getRoute('forgottenPassword'),
      query: {
        email: getValues('email'),
      },
    })
  }

  return (
    <FormProvider {...form}>
      <Container pt="10">
        <Layout
          title={isLogin ? t('AuthScreen.welcomeBack') : t('AuthScreen.createAnAccount')}
          pillColor="peach.400"
          description={
            isLogin
              ? t('AuthScreen.logInToYourAccount')
              : t('AuthScreen.welcomeRegisterAccountUsingEmail')
          }
          descriptionColor="gray.300"
        >
          <Stack as="form" width="100%" onSubmit={handleSubmit(onSubmit)} spacing="7">
            <Stack spacing="4">
              <InputWrapper
                id="email"
                autoFocus={!email}
                leftIcon={<Icon as={FiMail} />}
                errorMessage={errors.email?.message}
                {...register('email', emailValidationRegisterOptions(t))}
                type="email"
                placeholder={t('AuthScreen.emailPlaceholder')}
              />
              {!isCodeFieldOpen && isPasswordFieldOpen && (
                <Controller
                  name="password"
                  control={control}
                  rules={
                    isLogin
                      ? undefined
                      : {
                          required: t('passwordRequirements.passwordIsRequired'),
                          validate: (value) => {
                            if (getPasswordStrength(value) !== 100) {
                              return t('passwordRequirements.errorMessage', {
                                n: minPasswordLength,
                              })
                            }
                            return true
                          },
                        }
                  }
                  render={({ field }) => (
                    <PasswordStrength
                      {...field}
                      id="password"
                      name="password"
                      errorMessage={errors.password?.message}
                    />
                  )}
                />
              )}
              {isCodeFieldOpen && (
                <InputWrapper
                  id="code"
                  errorMessage={errors.code?.message}
                  leftIcon={<Icon as={MdKey} />}
                  label={t('CodeScreen.loginCodeLabel')}
                  {...register('code', {
                    required: t('CodeScreen.incorrectCode'),
                  })}
                  type="text"
                  placeholder={t('CodeScreen.pasteLoginCode')}
                />
              )}
            </Stack>
            {false && !isLogin && (
              <FormControl isInvalid={!!errors.agreement}>
                <Checkbox
                  id="agreement"
                  {...register('agreement', {
                    required: t('AuthScreen.youMustAgreeWithTerms'),
                  })}
                >
                  <Text pl="2" fontSize="sm" color="gray.700">
                    By signing up, I agree to{' '}
                    <Text as="span" fontWeight="bold">
                      {t('AuthScreen.termsOfUsePrivacyPolicy')}
                    </Text>
                    .
                  </Text>
                </Checkbox>
                <FormErrorMessage>{errors.agreement?.message}</FormErrorMessage>
              </FormControl>
            )}
            {isPasswordFieldOpen && isLogin && (
              <Button
                variant="link"
                fontWeight="medium"
                size="sm"
                onClick={handleForgotPassword}
              >
                {t('AuthScreen.forgotPassword')}
              </Button>
            )}
            {!isCodeFieldOpen && isLogin && (
              <Button
                variant="link"
                fontWeight="medium"
                onClick={onTogglePasswordOrSubmitIfEmailIsPresent}
              >
                {t(
                  isPasswordFieldOpen
                    ? 'AuthScreen.loginWithYourEmail'
                    : 'AuthScreen.loginWithPassword'
                )}
              </Button>
            )}
            <Button type="submit" variant="solid" isLoading={isSigningIn || isSubmitting}>
              {t(
                isLogin
                  ? isPasswordFieldOpen
                    ? 'AuthScreen.loginWithPassword'
                    : 'AuthScreen.loginWithEmailCode'
                  : 'AuthScreen.signUp'
              )}
            </Button>
            {isCodeFieldOpen && <ResendCode onClick={handleResendCode} />}
          </Stack>
          {false && (
            <>
              <StrikeThrough>
                <Text fontSize={{ base: 'sm', md: 'md' }}>Or with social accounts</Text>
              </StrikeThrough>
              <HStack mt="6" spacing="6" justifyContent="center">
                <SocialButton>f</SocialButton>
                <SocialButton>g</SocialButton>
                <SocialButton>t</SocialButton>
                <SocialButton>a</SocialButton>
              </HStack>
            </>
          )}
          <CreateOrLoginLink isLogin={isLogin} />
        </Layout>
      </Container>
    </FormProvider>
  )
}
