import { Heading } from '@chakra-ui/react'
import React from 'react'
import { HeadingPill } from './HeadingPill'

interface Props {
  title: string
  pillColor: string
}

export const HeadingWithPill = ({ title, pillColor }: Props) => {
  const titleComponents = React.useMemo(() => {
    const titleSplit = title.split(' ')
    return [titleSplit.slice(0, -1).join(' '), titleSplit.pop()]
  }, [title])

  return (
    <Heading textAlign="center">
      {titleComponents[0]} <HeadingPill bgColor={pillColor}>{titleComponents[1]}</HeadingPill>
    </Heading>
  )
}
