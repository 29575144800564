import { forwardRef, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import {
  Icon,
  Popover,
  PopoverAnchor,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  Progress,
  Stack,
} from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import { InputWrapper, InputWrapperProps } from 'components/InputWrapper'
import { FluentLockClosedIcon } from 'components/Icons'
import { PasswordRequirement } from './PasswordRequirement'

/**
 * Requirements for a password
 */
const requirements = [
  { re: /[0-9]/, key: 'includesNumber' },
  { re: /[a-z]/, key: 'includesLowercaseLetter' },
  { re: /[A-Z]/, key: 'includesUppercaseLetter' },
  // { re: /[$&+,:;=?@#|'<>.^*()%!-]/, key: 'includesSpecialCharacter' },
]

/**
 * Minimum length of a password
 */
export const minPasswordLength = 6

/**
 * Function to calculate the strength of a password
 * @param password Password as plain text
 * @returns Strength of the password as a number between 0 and 100
 */
export const getPasswordStrength = (password?: string) => {
  if (!password) {
    return 0
  }

  let multiplier = password.length > 5 ? 0 : 1

  requirements.forEach((requirement) => {
    if (!requirement.re.test(password)) {
      multiplier += 1
    }
  })

  return Math.max(100 - (100 / (requirements.length + 1)) * multiplier, 10)
}

export const PasswordStrength = forwardRef<HTMLInputElement, InputWrapperProps>(
  ({ errorMessage, value: _, ...props }, ref) => {
    const { watch } = useFormContext<{ password: string }>()

    const value = watch('password')
    const { t } = useTranslation('auth')
    const [isPopoverOpened, setIsPopoverOpened] = useState(false)

    const requirementsTranslated = useMemo(
      () =>
        requirements.map((requirement) => ({
          ...requirement,
          label: t(`passwordRequirements.${requirement.key}`),
        })),
      [t]
    )

    const checks = requirementsTranslated.map((requirement, index) => (
      <PasswordRequirement
        key={index}
        label={requirement.label}
        meets={!!value && requirement.re.test(value)}
      />
    ))

    const strength = getPasswordStrength(value)
    const color = strength === 100 ? 'teal' : strength > 50 ? 'yellow' : 'red'

    return (
      <Popover autoFocus={false} closeOnBlur={false} isOpen={isPopoverOpened} placement="bottom">
        <PopoverAnchor>
          <InputWrapper
            ref={ref}
            placeholder={t('AuthScreen.passwordPlaceholder')}
            type="password"
            leftIcon={<Icon as={FluentLockClosedIcon} />}
            // TODO: Show / hide password
            value={value}
            errorMessage={!isPopoverOpened ? errorMessage : undefined}
            isError={!!errorMessage}
            {...props}
            onFocus={() => setIsPopoverOpened(true)}
            onBlur={() => setIsPopoverOpened(false)}
          />
        </PopoverAnchor>
        <PopoverContent tabIndex={-1}>
          <PopoverArrow />
          <PopoverBody as={Stack} spacing={3} tabIndex={-1} pb={4}>
            <Progress
              colorScheme={color}
              value={strength}
              size="xs"
              borderRadius="xl"
              mt={2}
              mb={2}
            />
            <PasswordRequirement
              label={t('passwordRequirements.minLength', { n: minPasswordLength })}
              meets={!!value && value.length >= minPasswordLength}
            />
            {checks}
          </PopoverBody>
        </PopoverContent>
      </Popover>
    )
  }
)

PasswordStrength.displayName = 'PasswordStrength'
