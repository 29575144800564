import React from 'react'
import { chakra, Stack, Text } from '@chakra-ui/react'
import Container from 'components/Container'
import { HeadingWithPill } from 'components/HeadingWithPill'

const Description = chakra(Text, {
  baseStyle: {
    textAlign: 'center',
    fontSize: { base: 'sm', md: 'lg' },
    lineHeight: 'tall',
  },
})

interface LayoutProps {
  title: string
  pillColor: string
  description: React.ReactNode
  children: React.ReactNode
  descriptionColor?: string
}

export const Layout: React.FC<LayoutProps> = ({
  title,
  pillColor,
  description,
  descriptionColor = 'gray.700',
  children,
}) => (
    <Container p="0" px="0" maxW="34rem">
      <Stack spacing="6">
        <HeadingWithPill title={title} pillColor={pillColor} />
        <Description color={descriptionColor}>{description}</Description>
      </Stack>
      <Stack mt={{ base: '6', md: '10' }} spacing="12">
        {React.Children.map(children, (child) => (
          <Stack spacing={{ base: '6', md: '10' }}>{child}</Stack>
        ))}
      </Stack>
    </Container>
  )
